// Bootstrap Framework
import './scss/index.scss';
import jquery from 'jquery';
window.$ = window.jQuery = jquery;
import 'jquery-validation';
import 'jquery-datetimepicker';
import 'jquery-datetimepicker/jquery.datetimepicker.css';
import Swal from 'sweetalert2';
window.Swal = Swal;
import 'sweetalert2/dist/sweetalert2.css';
import 'select2';
import 'select2/dist/css/select2.css';
import 'popper.js';
import 'bootstrap';
import feather from 'feather-icons';
import Dropzone from 'dropzone';
import 'jquery-match-height';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import itLocale from '@fullcalendar/core/locales/it';

import { Moncal } from "./moncaljs/index";


/* document.addEventListener('DOMContentLoaded', function () {
    if (window.location.href.indexOf("Calendar") > -1) {
        var root = window["ROOT_SITE"];
        var calendarEl = document.getElementById('calendar');

        var calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin],
            headerToolbar: {
                left: 'today,prev,next,title',
                right: 'dayGridMonth,dayGridWeek',
                center: '',
            },
            locale: itLocale,
            displayEventEnd: true,
            events: {
                url: root+'/Courses/CalendarDates',
                method: 'POST', 
                failure: function () {
                    alert('there was an error while fetching events!');
                }
            },
            eventContent: function(args) {
                console.log(args);
                var color = args.event.extendedProps.category;
                var time = (args.event.start.getHours() < 10 ? '0' : '') + args.event.start.getHours() + ':' + (args.event.start.getMinutes() < 10 ? '0' : '') + args.event.start.getMinutes() + " - " + args.event.extendedProps.orafine;
                var title = args.event.title;
                var id = args.event.extendedProps.id;
                if (args.event.end >= Date.now()) {
                    return { html: '<a href="' + root + '/Courses/Details/' + id + '" class="calev calev--' + color + '"><span>' + title + '</span><span>Ore - ' + time + '</span></a>' };
                } else {
                    color = "grey";
                    return { html: '<a href="#" class="calev calev--' + color + '"><span>' + title + '</span><span>Ore - ' + time + '</span></a>' };
                }
                
            }
        });

        
        calendar.render();
        console.log("##EVENTI##");
        console.log(calendar.getEvents());
    }
    
}); */

$(function () {

    //#region javascript francesco 

    Dropzone.prototype.defaultOptions.dictDefaultMessage =
        'Carica qui i tuoi file';
    Dropzone.prototype.defaultOptions.dictFallbackMessage =
        "Il tuo browser non supporta il  drag'n'drop file uploads.";
    Dropzone.prototype.defaultOptions.dictFallbackText =
        'Please use the fallback form below to upload your files like in the olden days.';
    Dropzone.prototype.defaultOptions.dictFileTooBig =
        'Il file &egrave; troppo pesante ({{filesize}}MB). Massima grandezza file: {{maxFilesize}}MB.';
    Dropzone.prototype.defaultOptions.dictInvalidFileType =
        'Non puoi caricare un file di questo tipo';
    Dropzone.prototype.defaultOptions.dictResponseError =
        'Server responded with {{statusCode}} code.';
    Dropzone.prototype.defaultOptions.dictCancelUpload = 'Interrompi caricamento';
    Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation =
        'Sei sicuro di voler interrompere il caricamento?';
    Dropzone.prototype.defaultOptions.dictRemoveFile = 'Rimuovi file';
    Dropzone.prototype.defaultOptions.dictMaxFilesExceeded =
        'Non puoi pi&ugrave; caricare altri files';

    

    function RenderBaseAttachments() {
        $('.dropzone-control').each(function () {
            var $this = $(this);
            var hidden = $('#' + $this.data('hiddenfield'));
            var filelist = $('#' + $this.data('filelist'));
            $this.removeClass('d-none');
            filelist.addClass('d-none');
            var attachments = [];
            if (hidden.val() == '') {
                hidden.val('[]');
            }
            attachments = JSON.parse(hidden.val());
            for (var i = 0; i < attachments.length; i++) {
                if (attachments[i].Type == $this.data('type')) {
                    filelist.html(
                        attachment_template({
                            Attachments: [
                                {
                                    OriginalFileName: attachments[i].OriginalFileName,
                                    Size: humanFileSize(attachments[i].FileWeight, true),
                                    Icon: getExtensionIcon(attachments[i].FileExtension),
                                    IsImage: isImage(attachments[i].FileExtension),
                                    AttachmentId: attachments[i].AttachmentId,
                                    Hidden: $this.data('hiddenfield'),
                                    Url:
                                        ROOT_SITE +
                                        'Attachments/?type=' +
                                        attachments[i].Type +
                                        '&filename=' +
                                        attachments[i].FileName +
                                        '&originalfilename=' +
                                        attachments[i].OriginalFileName
                                }
                            ]
                        })
                    );
                    filelist.removeClass('d-none');
                    $this.addClass('d-none');
                    break;
                }
            }
        });
    }

    $('.dropzone-control').each(function () {
        var $this = $(this);

        var dropzone_options = {
            url: ROOT_SITE + 'Partecipazione/SaveAttachment',
            method: 'POST',
            maxFiles: 1,
            paramName: 'file',
            uploadMultiple: false,
            clickable: true,
            maxFilesize: parseInt($this.data('maxfilesize')),
            acceptedFiles: $this.data('acceptedfiles'),
            previewsContainer: '#' + $this.data('preview'),
            init: function () {
                this.on('sending', function (file, xhr, data) {
                    $('#' + $this.data('error'))
                        .addClass('d-none')
                        .html('');
                    data.append('Type', $this.data('type'));
                });

                this.on('error', function (a, b) {
                    this.removeFile(a);
                    RenderBaseAttachments();
                    $('#' + $this.data('error'))
                        .removeClass('d-none')
                        .html(b);
                });

                this.on('success', function (a, b) {
                    var me = this;
                    if (b.Attachment) {
                        var hidden = $('#' + $this.data('hiddenfield'));

                        var attachments = [];
                        if (hidden.val() != '') {
                            attachments = JSON.parse(hidden.val());
                        }
                        for (var i = 0; i < attachments.length; i++) {
                            if (attachments[i].Type == $this.data('type')) {
                                attachments.splice(i, 1);
                            }
                        }

                        attachments.push(b.Attachment);

                        hidden.val(JSON.stringify(attachments));
                    }

                    this.removeFile(a);
                    RenderBaseAttachments();
                });
            }
        };
        var dz = new Dropzone('#' + $this.attr('id'), dropzone_options);
    });

    $(".showImagePreview").click(function () {
        $("#dialog").dialog({
            resizable: false,
            height: "auto",
            width: "auto"
        });
    });

    $("submit").click(function () {
        $(this).attr('disabled', 'disabled');
    });

    $("#search").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#table-main tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
    
    $(".chose-user").select2({
        minimumInputLength: 3,
        ajax: {
            url: window["ROOT_SITE"] + '/api/LBFakeGetUsers?search=' + $(this).val(),
            processResults: function (data) {
                users = data.items;
                $(data.items).each(function (index) {
                    console.log($(this));
                });
                return {
                    results: $.map(data.items, function (users) {
                        return {
                            text: users.name + ' ' + users.surname + ' - ' + users.company + ' - ' + users.email,
                            id: users.id
                        }
                    }),
                };
            }
        }
    });
    $('#btn-submit-loaduser').click(function () {
        var selectedID = $(".chose-user").val();
        var user = $.grep(users, function (u) {
            return u.id == selectedID;
        })[0];

        $.ajax({
            url: window["ROOT_SITE"] + "/Users/Activation",
            data: JSON.stringify(user),
            contentType: "application/json;",
            dataType: "json",
            type: "POST",
            success: function (result) {
                Swal.fire(
                    'Abilitato!',
                    'Utente correttamente inserito a sistema!',
                    'success'
                )
            },
            error: function (result) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Qualcosa è andato storto!',
                    footer: 'Utente non riconosciuto.'
                })
            }
        });
        console.log(user);
    });

    $('#openTabForDocument').click(function (e) {
        e.preventDefault();
        var type = $(this).data("type");
        var filename = $(this).data("filename");
        window.open(window["ROOT_SITE"] + '/DownloadDocuments?type=' + type + '&filename=' + filename);
    });
    $('#openPreview').click(function (e) {
        e.preventDefault();
        var id = $(this).data("id");
        window.open(window["ROOT_SITE"] + '/PreviewDocument/' + id);
    });

    $(".only-numeric").keydown(function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
            // Allow: Ctrl+A, Command+A
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });

    $(".btn-access-content").click(function (e) {
        e.preventDefault();
        var type = $(this).data("type");
        var purchased = $(this).data("purchased");
        if (purchased !== undefined && purchased !== null && purchased == 0) {
            Swal.fire({
                title: 'Sicuro di voler accedere a questo Contenuto?',
                text: "Il suo costo è di " + $(this).data("cost") + " crediti",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sì!',
                cancelButtonText: 'No'
            }).then((result) => {
                console.log("RESULT");
                if (result.value) {
                    console.log("OK");
                    console.log("Tentato redirect a " + window["ROOT_SITE"] + '/DownloadDocuments?type=' + $(this).data("type") + '&filename=' + $(this).data("filename")
                        + '&cost=' + $(this).data("cost")
                        + '&fileID=' + $(this).data("id"));
                    location.href = window["ROOT_SITE"] + '/DownloadDocuments?type=' + $(this).data("type") + '&filename=' + $(this).data("filename")
                        + '&cost=' + $(this).data("cost")
                        + '&fileID=' + $(this).data("id");
                    //$.ajax({
                    //    url: window["ROOT_SITE"] + "/" + type + "/DownloadDocuments/?id=" + $(this).data("content"),
                    //    method: 'GET',
                    //    success(a, b) {
                    //        location.reload();
                    //    }

                    //});

                } else {
                    e.preventDefault();
                }
            });
        } else {
            location.href = window["ROOT_SITE"] + '/DownloadDocuments?type=' + $(this).data("type") + '&filename=' + $(this).data("filename")
                + '&cost=' + $(this).data("cost")
                + '&fileID=' + $(this).data("id");
        }
        
        
    });

    $(".btn-access-live").click(function (e) {
        e.preventDefault();
        var purchased = $(this).data("purchased");
        if (purchased !== undefined && purchased !== null && purchased == 0) {
            Swal.fire({
                title: 'Sicuro di voler accedere a questa Live?',
                text: "Il suo costo è di " + $(this).data("cost") + " crediti",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sì!',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    location.href = window["ROOT_SITE"] + '/Lives/Details?id=' + $(this).data("id");
                    //$.ajax({
                    //    url: window["ROOT_SITE"] + "/" + type + "/DownloadDocuments/?id=" + $(this).data("content"),
                    //    method: 'GET',
                    //    success(a, b) {
                    //        location.reload();
                    //    }

                    //});

                } else {
                    e.preventDefault();
                }
            });
        } else {
            location.href = window["ROOT_SITE"] + '/Lives/Details?id=' + $(this).data("id");
        }
        
    });

    $(".btn-access-video").click(function (e) {
        e.preventDefault();
        var purchased = $(this).data("purchased");
        if (purchased !== undefined && purchased !== null && purchased == 0) {
            Swal.fire({
                title: 'Sicuro di voler accedere a questo Video?',
                text: "Il suo costo è di " + $(this).data("cost") + " crediti",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sì!',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    location.href = window["ROOT_SITE"] + '/Videos/Details?id=' + $(this).data("id");
                    //$.ajax({
                    //    url: window["ROOT_SITE"] + "/" + type + "/DownloadDocuments/?id=" + $(this).data("content"),
                    //    method: 'GET',
                    //    success(a, b) {
                    //        location.reload();
                    //    }

                    //});

                } else {
                    e.preventDefault();
                }
            });
        } else {
            location.href = window["ROOT_SITE"] + '/Videos/Details?id=' + $(this).data("id");
        }
        
    });

    // OLD
    //text: "Il suo costo è di " + $(this).data("cost") + " crediti",
    $(".btn-access-corso").click(function (e) {
        e.preventDefault();
        Swal.fire({
            title: 'Sicuro di voler acquistare questo Corso?',
            text: "Per poter accedere al corso attiva l'abbonamento nella tua area personale!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sì!',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                var selectedDateNumber = $("input[name=selected_date]:checked").val();
                location.href = window["ROOT_SITE"] + '/Courses/Purchase?id=' + $(this).data("id") + '&sdn=' + selectedDateNumber;
                //$.ajax({
                //    url: window["ROOT_SITE"] + "/" + type + "/DownloadDocuments/?id=" + $(this).data("content"),
                //    method: 'GET',
                //    success(a, b) {
                //        location.reload();
                //    }

                //});

            } else {
                e.preventDefault();
            }
        });
    });

    $(".btn-doc-delete").click(function (e) {
        e.preventDefault();
        var type = $(this).data("type");
        
        Swal.fire({
            title: 'Sicuro di voler cancellare questo Elemento?',
            text: "Non sarai in grado di recuperarlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sì, Cancella!',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                $.ajax({
                    url: window["ROOT_SITE"] + "/" + type + "/DeleteConfirmed?id=" + $(this).data("id"),
                    method: 'GET',
                    success(a, b) {
                        location.reload();
                    }

                });

            } else {
                e.preventDefault();
            }
        });
    });

    jQuery.datetimepicker.setLocale('it');
    $('.has-datepicker').datetimepicker({
        format:'d/m/Y H:i',
        autoclose: true,
        todayHighlight: true
    });

    $(".only-numeric").keydown(function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
            // Allow: Ctrl+A, Command+A
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });
    //#endregion

    //#region feather icons
    feather.replace();
    //#endregion

    
});

//#region calendar
$(function () {
    var url = window["ROOT_SITE"] + '/Courses/CalendarDates';
    let events = [];
    if (window.location.href.indexOf("Calendar") > -1) {
        $.ajax({
            type: "POST",
            url: url,
            success: function (data) {
                var dataEvents = {};
                var onlyevent = true;
                //console.log(data);
                for (var i = 0; i < data.length; i++) {
                    let event = {};
                    var date = data[i].start;
                    //var date2 = date.toLocaleDateString("en-US").replace('/', '-').replace('/', '-');
                    var date2 = date;
                    console.log(date2);
                    var dataInizio = new Date(data[i].start);
                    var link = window["ROOT_SITE"] + "/Courses/Details?id=" + data[i].extendedProps.id;

                    var color = data[i].extendedProps.category;
                    var today = new Date();
                    if (new Date(data[i].end) < today) {
                        color = "grey";
                        link = "#";
                    }

                    var linkImg = window["ROOT_SITE"] + "/images/aboutlux/" + color + ".png";
                    var html = "";
                    html += "<a class='evento evento-" + color + "' href='" + link + "'>";
                    html += '<div class="evento__content">';
                    html += '<div class="evento__content__title">';
                    html += '<img class="evento__content__title__img " src="' + linkImg + '">';
                    html += '<div class="evento__content__title__text">' + data[i].title + '</div>';
                    html += '</div><div class="evento__content__date">';
                    html += '<img class="evento__content__date__img" src="' + window["ROOT_SITE"] + '/images/ico-orologio.svg">';
                    html += '<div class="evento__content__date__text">' + dataInizio.getHours() + ":" + dataInizio.getMinutes().toString().padEnd(2, '0') + " - " + data[i].extendedProps.orafine + '</div>';
                    html += '</div></div></a>'

                    console.log(date);
                    event = {
                        date: date,
                        html: html,
                    };
                    events.push(event);

                    if (dataEvents[date2.toString()] != undefined) dataEvents[date2.toString()] += html;
                    else dataEvents[date2.toString()] = html;
                }
                //console.log(events);
                new Moncal(events);
            }
        });
    }


    /* let events = [
      {
        date: new Date(),
        html: "<p>evento</p>",
      },
      {
        date: new Date(),
        html: "<p>evento</p>",
      },
      {
        date: new Date(),
        html: "<p>evento</p>",
      },
      {
        date: new Date(),
        html: "<p>evento</p>",
      },
    ]; */
    
  });
  //#endregion